import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import Text from '@/components/Text/Text';
import { EmphasedText } from '@/types';
import {
  FeatureSection,
  FeatureTitle,
  FeatureList,
} from '@/components/Feature/Feature';
import Carousel from '@/components/Carousel/Carousel';
import { GlideSlideWrapper } from '@/components/Carousel/Carousel.style';

const AppCarouselWrapper = styled.div`
  .glide {
    width: 400px;
    margin: 0 auto;
    position: relative;

    @media only screen and (min-width: 1600px) {
      width: 640px;
    }

    @media only screen and (max-width: 991px) {
      width: 310px;
    }
    @media only screen and (max-width: 667px) {
      width: 260px;
    }

    .glide__slide {
      display: flex;
      justify-content: center;
      img {
        display: inline-block;
      }
    }
    .glide__bullets {
      display: flex;
      height: 4px;
      position: absolute;
      left: 50%;
      bottom: -21px;
      transform: translateX(-50%);
      > button.glide__bullet {
        height: 4px;
        width: 20px;
        border-radius: 5px;
        margin-left: 0;
        margin-right: 0;
        background-color: ${(props) => props.theme.colors.secondaryText};
        transition: width 0.3s ease;
        &.glide__bullet--active {
          width: 35px;
          background-color: ${(props) => props.theme.colors.primary};
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .appImage {
    width: 60%;

    @media (max-width: 1200px) {
      width: 70%;
    }

    @media (max-width: 992px) {
      width: 90%;
    }
  }
`;

type AppFeaturesData = {
  landingContentJson: {
    appFeatures: {
      step: number;
      title: EmphasedText[];
      description: string;
      features: string[];
      images: {
        id: number;
        alt: string;
        path: {
          childImageSharp: ImageDataLike;
        };
      }[];
    };
  };
};

export const query = graphql`
  {
    landingContentJson {
      appFeatures {
        description
        features
        step
        title {
          emphasis
          id
          text
        }
        images {
          id
          alt
          path {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;

const AppFeatures = () => {
  const {
    landingContentJson: { appFeatures },
  } = useStaticQuery<AppFeaturesData>(query);
  const { step, title, description, features, images } = appFeatures;

  return (
    <FeatureSection>
      <Flex
        sx={{
          '@media screen and (max-width: 768px)': {
            flexWrap: 'wrap-reverse',
          },
        }}
        className="featureRow"
        alignItems="center"
        justifyContent="center"
        mx="-15px"
      >
        <Box
          px="15px"
          width={[1, 1, 1 / 2]}
          flexBasis={['100%', '100%', '50%']}
          flexGrow={0}
          flexShrink={0}
        >
          <AppCarouselWrapper>
            <Carousel
              bullets
              controls={false}
              numberOfBullets={images.length}
              selector="appFeatureSlider"
            >
              {images.map((image) => (
                <GlideSlideWrapper
                  className="glide__slide"
                  key={`image__${image.id}`}
                >
                  <GatsbyImage
                    alt={image.alt}
                    image={getImage(image.path.childImageSharp)}
                    className="appImage"
                  />
                </GlideSlideWrapper>
              ))}
            </Carousel>
          </AppCarouselWrapper>
        </Box>
        <Box
          px="15px"
          width={[1, 1 / 2]}
          flexBasis={['100%', '100%', '50%']}
          className="featureContent"
        >
          <FeatureTitle step={step} title={title} />
          <Text as="p" textStyle="displayLarge">
            {description}
          </Text>
          <FeatureList items={features} />
        </Box>
      </Flex>
    </FeatureSection>
  );
};

export default AppFeatures;
