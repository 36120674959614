import React from 'react';
import Hero from '@/containers/Hero/Hero';
import Interest from '@/containers/Interest/Interest';
import BackOffice from '@/containers/BackOffice/BackOffice';
import AppFeatures from '@/containers/AppFeatures/AppFeatures';
import Reward from '@/containers/Reward/Reward';
import Testimonial from '@/containers/Testimonial/Testimonial';
import SEO from '@/components/SEO/SEO';
import SectionTitle from '@/components/SectionTitle/SectionTitle';

const Home = () => (
  <>
    <SEO title="Créez des programmes sportifs et générez des revenus" />
    <Hero />
    <Interest />
    <section data-spy id="fonctionnalites">
      <SectionTitle>Comment ça marche ?</SectionTitle>
      <BackOffice />
      <AppFeatures />
      <Reward />
    </section>
    <section data-spy id="temoignages">
      <SectionTitle>Témoignages</SectionTitle>
      <Testimonial />
    </section>
  </>
);

export default Home;
