import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Glide from '@glidejs/glide';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

import {
  DefaultBtn,
  GlideSlideWrapper,
} from '@/components/Carousel/Carousel.style';
import { ReviewCard } from '@/components/ReviewCard/ReviewCard';
import Carousel from '@/components/Carousel/Carousel';
import { FeatureSection } from '@/components/Feature/Feature';

const glideOptions: Glide.Options = {
  type: 'carousel',
  gap: 30,
  perView: 3,
  breakpoints: {
    991: {
      perView: 1,
    },
  },
};

type TestimonialData = {
  landingContentJson: {
    testimonial: {
      author: string;
      description: string;
      id: number;
      title: string;
    }[];
  };
};

export const CarouselWrapper = styled.div`
  padding-bottom: 6rem;

  .glide__controls {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    > div {
      position: absolute;
      top: 50%;

      &.glide__prev--area {
        left: -24px;
        transform: translate(-50%, -50%);
        @media only screen and (max-width: 480px) {
          left: 0;
        }
      }
      &.glide__next--area {
        right: -24px;
        transform: translate(50%, -50%);
        @media only screen and (max-width: 480px) {
          right: 0;
        }
      }
    }
  }

  .glide {
    margin: 0 auto;
    width: 870px;
    &:hover {
      .glide__controls {
        opacity: 1;
        visibility: visible;
      }
    }

    @media only screen and (min-width: 1600px) {
      width: 1200px;
    }

    @media only screen and (max-width: 991px) {
      width: 500px;
    }
    @media only screen and (max-width: 667px) {
      width: 280px;
    }
  }
`;

export const query = graphql`
  {
    landingContentJson {
      testimonial {
        author
        description
        id
        title
      }
    }
  }
`;

const Testimonial = () => {
  const {
    landingContentJson: { testimonial },
  } = useStaticQuery<TestimonialData>(query);

  return (
    <FeatureSection minHeight={0} py="0">
      <Flex
        className="featureRow"
        alignItems="center"
        justifyContent="center"
        mx="-15px"
        px="15px"
      >
        <Box sx={{ position: 'relative' }}>
          <Fade up delay={100}>
            <CarouselWrapper>
              <Carousel
                options={glideOptions}
                controls
                prevButton={
                  <DefaultBtn>
                    <MdNavigateBefore size={18} />
                  </DefaultBtn>
                }
                nextButton={
                  <DefaultBtn>
                    <MdNavigateNext size={18} />
                  </DefaultBtn>
                }
              >
                {testimonial.map((item) => (
                  <GlideSlideWrapper
                    className="glide__slide"
                    key={`testimonial--key${item.id}`}
                  >
                    <ReviewCard
                      title={item.title}
                      author={item.author}
                      description={item.description}
                    />
                  </GlideSlideWrapper>
                ))}
              </Carousel>
            </CarouselWrapper>
          </Fade>
        </Box>
      </Flex>
    </FeatureSection>
  );
};

export default Testimonial;
