import React from 'react';
import styled from 'styled-components';
import Text from '../Text/Text';

interface IconThumbnailProps {
  className?: string;
  icon: string;
  alt: string;
  title: string;
  subtitle: string;
}

interface SimpleThumbnailProps {
  className?: string;
  title: string;
  result: string;
  progress: string;
}

const IconThumbnailWrapper = styled.div`
  display: flex;
  align-items: stretch;
  /* width: 20rem; */
  padding: 0.8rem;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 0 0 20%; */

    img {
      height: 36px;
    }
  }

  @media (max-width: 992px) {
    border-radius: 8px;
    .icon img {
      height: 24px;
    }
  }

  .subtitle {
    color: ${(props) => props.theme.colors.secondaryText};
  }
`;

const IconThumbnailText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  text-transform: capitalize;
  padding: 0 1rem;

  @media (min-width: 1600px) {
    padding: 0 1.5rem;
  }

  @media (max-width: 992px) {
    padding: 0 0.8rem;
  }

  & > *:not(:last-child) {
    white-space: nowrap;
    margin-bottom: 0.8rem;
  }
`;

const SimpleThumnailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  text-transform: capitalize;
  border-radius: 15px;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);

  @media (max-width: 992px) {
    border-radius: 8px;
    padding: 0.8rem;
  }

  & > *:not(:last-child) {
    margin-right: 2.5rem;
  }

  .progress {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    border-radius: 8px;
    background-color: rgba(82, 207, 197, 0.2);
    color: rgba(82, 207, 197, 1);
  }
`;

export const IconThumbnail: React.FC<IconThumbnailProps> = ({
  icon,
  alt,
  title,
  subtitle,
  className,
}) => (
  <IconThumbnailWrapper className={className}>
    <div className="icon">
      <img src={icon} alt={alt} />
    </div>
    <IconThumbnailText>
      <Text fontSize={{ _: '1.4rem', lg: '1.6rem' }} fontWeight={700}>
        {title}
      </Text>
      <Text
        fontSize={{ _: '1.2rem', lg: '1.4rem' }}
        fontWeight={700}
        className="subtitle"
      >
        {subtitle}
      </Text>
    </IconThumbnailText>
  </IconThumbnailWrapper>
);

export const SimpleThumbnail: React.FC<SimpleThumbnailProps> = ({
  title,
  result,
  progress,
  className,
}) => (
  <SimpleThumnailWrapper className={className}>
    <Text fontSize={['1.4rem', '1.4rem', '1.4rem', '1.6rem']} fontWeight={700}>
      {title}
    </Text>
    <Text fontSize={['1.4rem', '1.4rem', '1.4rem', '1.6rem']} fontWeight={700}>
      {result}
    </Text>
    <div className="progress">
      <Text
        fontSize={['1.2rem', '1.2rem', '1.4rem', '1.6rem']}
        fontWeight={700}
      >
        {progress}
      </Text>
    </div>
  </SimpleThumnailWrapper>
);

IconThumbnail.defaultProps = {
  className: '',
};

SimpleThumbnail.defaultProps = {
  className: '',
};
