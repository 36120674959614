import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import { Container } from '@/components/Layout/Container';
import Text from '@/components/Text/Text';
import CTA from '@/components/CTA/CTA';

const HeroWrapper = styled.section`
  /* height: 80vh; */
  position: relative;
  overflow: hidden;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  clip-path: inset(0 0 0 0 round 0 0 30% 0);

  .heroImageWrapper {
    height: 85%;

    @media (max-width: 992px) {
      height: auto;
    }

    .heroImage {
      height: 100%;
    }
  }

  @media (max-width: 992px) {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    /* clip-path: none; */
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10rem;

  @media (max-width: 1500px) {
    padding: 4.5rem;
  }

  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.space[6]};
  }

  @media (max-width: 992px) {
    text-align: center;
    align-items: center;

    & > *:not(:last-child) {
      margin-bottom: ${(props) => props.theme.space[8]};
    }
  }
`;

const HeroBanner = styled(Flex)`
  height: 85vh;
  @media (max-width: 992px) {
    height: auto;
    padding-bottom: 3rem;
    align-content: flex-start;
  }
`;

type HeroData = {
  landingContentJson: {
    hero: {
      description: string;
      appImage: {
        childImageSharp: ImageDataLike;
      };
      title: string;
    };
  };
};

export const query = graphql`
  {
    landingContentJson {
      hero {
        description
        appImage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        title
      }
    }
  }
`;

const Hero: React.FC = () => {
  const {
    landingContentJson: {
      hero: {
        description,
        appImage: { childImageSharp: gatsbyAppImage },
        title,
      },
    },
  } = useStaticQuery<HeroData>(query);

  return (
    <HeroWrapper>
      <Container>
        <HeroBanner
          justifyItems="center"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Box
            width={['100%', '83%', '83%', '50%']}
            flexBasis={['100%', '83%', '83%', '50%']}
            flexGrow={0}
            flexShrink={0}
          >
            <HeroText>
              <Fade up delay={100}>
                <Text as="h1" textStyle="primaryHeading" color="white">
                  {title}
                </Text>
              </Fade>
              <Fade up delay={200}>
                <Text as="p" textStyle="displayLarge" color="white">
                  {description}
                </Text>
              </Fade>
              <Fade up delay={300}>
                <CTA
                  message="devenir créateur"
                  onClick={() => {
                    navigate('/contact');
                  }}
                />
              </Fade>
            </HeroText>
          </Box>
          <Box
            width={['83%', '83%', '75%', '50%']}
            flexBasis={['70%', '70%', '70%', '50%']}
            flexGrow={0}
            flexShrink={0}
            className="heroImageWrapper"
          >
            <GatsbyImage
              image={getImage(gatsbyAppImage)}
              alt="Palestra IOS App"
              objectFit="contain"
              className="heroImage"
            />
          </Box>
        </HeroBanner>
      </Container>
    </HeroWrapper>
  );
};

export default Hero;
