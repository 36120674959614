import React from 'react';
import styled from 'styled-components';
import { layout, space, LayoutProps, SpaceProps } from 'styled-system';
import { BsCheckCircle } from 'react-icons/bs';

import { EmphasedText } from '@/types';
import { Container } from '@/components/Layout/Container';

import Text, { makeEmphasedText } from '../Text/Text';

const FeatureSectionWrapper = styled.section<LayoutProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 60vh; */

  .container {
    padding-top: 4rem;
  }

  .featureRow {
    padding: 0 10rem;

    @media (max-width: 1500px) {
      padding: 0 4.5rem;
    }

    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
  }

  .featureContent {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    @media (max-width: 768px) {
      margin-bottom: 4rem;
    }
  }

  ${layout}
  ${space}
`;

export const FeatureSection: React.FC<LayoutProps & SpaceProps> = ({
  children,
  ...props
}) => (
  <FeatureSectionWrapper {...props}>
    <Container className="container">{children}</Container>
  </FeatureSectionWrapper>
);

const FeatureKeyPointWrapper = styled.li`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 2rem;
  }

  .icon {
    flex-shrink: 0;
    color: ${(props) => props.theme.colors.primary};
  }

  .featureText {
    flex-grow: 1;
  }
`;

export const FeatureKeyPoint: React.FC = ({ children }) => (
  <FeatureKeyPointWrapper>
    <BsCheckCircle className="icon" size="18" />
    <Text as="span" className="featureText" textStyle="displayLarge">
      {children}
    </Text>
  </FeatureKeyPointWrapper>
);

export const FeatureTitle: React.FC<{
  step: number;
  title: EmphasedText[];
}> = ({ step, title }) => (
  <Text as="h2" textStyle="secondaryHeading">
    {step}
    {'. '}
    {makeEmphasedText(title)}
  </Text>
);

const FeatureListWrapper = styled.ul`
  & > li:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const FeatureList: React.FC<{ items: string[] }> = ({ items }) => (
  <FeatureListWrapper>
    {items.map((featurePoint, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <FeatureKeyPoint key={index}>{featurePoint}</FeatureKeyPoint>
    ))}
  </FeatureListWrapper>
);
