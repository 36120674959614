import React, { useEffect } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import Glide from '@glidejs/glide';

import '@glidejs/glide/dist/css/glide.core.min.css';
import {
  ButtonControlWrapper,
  ButtonWrapper,
  ButtonControlWrapperProps,
  ButtonWrapperProps,
  BulletControlWrapper,
  BulletControlProps,
  BulletButton,
  BulletButtonProps,
} from './Carousel.style';

const GLIDE_DEFAULT_OPTIONS: Glide.Options = {
  type: 'carousel',
  gap: 0,
  autoplay: 5000,
  perView: 1,
  animationDuration: 700,
};

const GlideWrapper = styled.div<LayoutProps>`
  ${layout}
`;

interface CarouselProps {
  selector?: string;
  className?: string;
  options?: Glide.Options;
  controls?: boolean;
  bullets?: boolean;
  numberOfBullets?: number;
  buttonWrapperStyle?: ButtonControlWrapperProps;
  bulletWrapperStyle?: BulletControlProps;
  bulletButtonStyle?: BulletButtonProps;
  prevWrapper?: ButtonWrapperProps;
  nextWrapper?: ButtonWrapperProps;
  prevButton?: React.ReactNode;
  nextButton?: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({
  selector,
  className,
  children,
  options,
  controls,
  buttonWrapperStyle,
  prevWrapper,
  nextWrapper,
  prevButton,
  nextButton,
  bullets,
  numberOfBullets,
  bulletWrapperStyle,
  bulletButtonStyle,
}) => {
  // Add all classs to an array
  const addAllClasses = ['glide'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  useEffect(() => {
    const glide = new Glide(`#${selector}`, options);
    const mounted = glide.mount();

    return () => {
      mounted.destroy();
    };
  });

  return (
    <GlideWrapper className={addAllClasses.join(' ')} id={selector}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">{children}</ul>
      </div>
      {controls && (
        <ButtonControlWrapper
          className="glide__controls"
          data-glide-el="controls"
          {...buttonWrapperStyle}
        >
          <ButtonWrapper
            {...prevWrapper}
            className="glide__prev--area"
            data-glide-dir="<"
            aria-label="prev"
          >
            {prevButton}
          </ButtonWrapper>
          <ButtonWrapper
            {...nextWrapper}
            className="glide__next--area"
            data-glide-dir=">"
            aria-label="next"
          >
            {nextButton}
          </ButtonWrapper>
        </ButtonControlWrapper>
      )}
      {bullets && (
        <BulletControlWrapper
          className="glide__bullets"
          data-glide-el="controls[nav]"
          {...bulletWrapperStyle}
        >
          {Array.from({ length: numberOfBullets }).map((_, index) => (
            <BulletButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="glide__bullet"
              data-glide-dir={`=${index}`}
              aria-label={`bullet${index + 1}`}
              {...bulletButtonStyle}
            />
          ))}
        </BulletControlWrapper>
      )}
    </GlideWrapper>
  );
};

Carousel.defaultProps = {
  selector: 'glide',
  className: '',
  controls: true,
  bullets: false,
  numberOfBullets: 0,
  options: GLIDE_DEFAULT_OPTIONS,
  buttonWrapperStyle: {},
  bulletWrapperStyle: {},
  bulletButtonStyle: {},
  prevWrapper: {},
  nextWrapper: {},
  prevButton: null,
  nextButton: null,
};

export default Carousel;
