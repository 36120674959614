import React from 'react';
import Fade from 'react-reveal/Fade';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Container } from '@/components/Layout/Container';
import Text, { makeEmphasedText } from '@/components/Text/Text';
import { EmphasedText } from '@/types';

const InterestWrapper = styled.section`
  padding: 5rem 0 2rem;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > *:not(:last-child) {
      margin-bottom: ${(props) => props.theme.space[8]};
    }

    @media (max-width: 992px) {
      margin-top: -2rem;
    }
  }

  .title {
    padding: 0 10px;
  }

  .description {
    padding: 0 10px;
    margin: 0 auto;
  }

  .imageWrapper {
    position: relative;
    width: 80%;
    padding-bottom: 50px;

    @media (max-width: 1200px) {
      width: 90%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    .desktopImage {
      width: 100%;
    }

    .appImage {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%;
    }
  }
`;

type InterestData = {
  landingContentJson: {
    interest: {
      description: string;
      appImage: {
        childImageSharp: ImageDataLike;
      };
      desktopImage: {
        childImageSharp: ImageDataLike;
      };
      title: EmphasedText[];
    };
  };
};

export const query = graphql`
  {
    landingContentJson {
      interest {
        description
        title {
          id
          emphasis
          text
        }
        desktopImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        appImage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

const Interest = () => {
  const {
    landingContentJson: { interest },
  } = useStaticQuery<InterestData>(query);
  const { description, title, desktopImage, appImage } = interest;
  return (
    <InterestWrapper id="presentation" data-spy>
      <Container className="container">
        <Fade up>
          <Text
            as="h2"
            textAlign="center"
            textStyle="secondaryHeading"
            className="title"
          >
            {makeEmphasedText(title)}
          </Text>
          <Text
            as="p"
            textStyle="displayLarge"
            textAlign="center"
            className="description"
            width={[1, 0.8, 0.8, 0.8, 0.65]}
          >
            {description}
          </Text>
        </Fade>
        <div className="imageWrapper">
          <Fade left>
            <GatsbyImage
              image={getImage(desktopImage.childImageSharp)}
              alt="Palestra backoffice"
              className="desktopImage"
            />
          </Fade>
          <Fade right>
            <GatsbyImage
              image={getImage(appImage.childImageSharp)}
              alt="Palestra app start program"
              className="appImage"
            />
          </Fade>
        </div>
      </Container>
    </InterestWrapper>
  );
};

export default Interest;
