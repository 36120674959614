import styled from 'styled-components';
import {
  width,
  height,
  display,
  space,
  color,
  borders,
  boxShadow,
  borderRadius,
  position,
  top,
  left,
  right,
  bottom,
  alignItems,
  justifyContent,
  flex,
  WidthProps,
  HeightProps,
  DisplayProps,
  SpaceProps,
  ColorProps,
  BorderProps,
  BoxShadowProps,
  BorderRadiusProps,
  PositionProps,
  FlexboxProps,
  LayoutProps,
  AlignItemsProps,
  JustifyContentProps,
} from 'styled-system';

// Glide wrapper style
export const GlideWrapper = styled.div<WidthProps & HeightProps & SpaceProps>`
  ${width}
  ${height}
  ${space}
`;

// Glide slide wrapper style
export const GlideSlideWrapper = styled.li<
  SpaceProps & ColorProps & BorderProps & BoxShadowProps & BorderRadiusProps
>`
  ${space}
  ${color}
  ${borders}
  ${boxShadow}
  ${borderRadius}
`;

export interface ButtonWrapperProps
  extends DisplayProps,
    SpaceProps,
    BorderProps,
    BoxShadowProps,
    PositionProps {}

// Button wrapper style
export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: inline-block;
  ${display}
  ${space}
  ${borders}
  ${boxShadow}
  ${borderRadius}
  ${position}
  ${top}
  ${left}
  ${right}
  ${bottom}
`;

export interface ButtonControlWrapperProps
  extends DisplayProps,
    SpaceProps,
    AlignItemsProps,
    JustifyContentProps,
    PositionProps {}

// ButtonControlWrapper style
export const ButtonControlWrapper = styled.div<ButtonControlWrapperProps>`
  ${display}
  ${space}
  ${alignItems}
  ${justifyContent}
  ${position}
  ${top}
  ${left}
  ${right}
  ${bottom}
`;

export interface BulletControlProps
  extends DisplayProps,
    SpaceProps,
    FlexboxProps {}
// BulletControlWrapper style
export const BulletControlWrapper = styled.div<BulletControlProps>`
  ${display}
  ${space}
  ${alignItems}
  ${justifyContent}
  ${flex}
`;

export interface BulletButtonProps
  extends DisplayProps,
    SpaceProps,
    BorderProps,
    BoxShadowProps,
    LayoutProps {}

// BulletButton style
export const BulletButton = styled.button<BulletButtonProps>`
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 4px;
  border: 0;
  padding: 0;
  outline: none;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.secondaryText};

  &:hover,
  &.glide__bullet--active {
    background-color: ${(props) => props.theme.colors.primary};
  }

  ${display}
  ${space}
  ${color}
  ${borders}
  ${boxShadow}
  ${borderRadius}
  ${width}
  ${height}
`;

// default button style
export const DefaultBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  border: 0px;
  color: #fff;

  &:hover {
    box-shadow: ${(props) => props.theme.colors.primary} 0px 12px 24px -10px;
  }
`;
