import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import Text from '@/components/Text/Text';

import { EmphasedText } from '@/types';
import {
  FeatureSection,
  FeatureTitle,
  FeatureList,
} from '@/components/Feature/Feature';
import {
  IconThumbnail,
  SimpleThumbnail,
} from '@/components/Thumbnail/Thumbnail';

const RewardImageSection = styled.div`
  padding: 4rem 1rem;
  position: relative;

  /* @media (max-width: 768px) {
    margin-top: -2rem;
  } */

  .rewardImage {
    border-radius: 12px;
    width: 100%;
  }

  .rewardThumbnail {
    background-color: #fff;
    position: absolute;
    z-index: 10;
  }

  .rewardThumbnail:nth-child(1) {
    top: 0;
    right: -1.5rem;
  }

  .rewardThumbnail:nth-child(2) {
    bottom: 0;
    left: -1.5rem;
  }

  .rewardThumbnail:nth-child(3) {
    right: -1.5rem;
    bottom: 3rem;
  }
`;

type RewardData = {
  landingContentJson: {
    reward: {
      step: number;
      title: EmphasedText[];
      description: string;
      features: string[];
      image: {
        alt: string;
        path: {
          childImageSharp: ImageDataLike;
        };
      };
      iconThumbnails: {
        icon: {
          publicURL: string;
        };
        id: number;
        title: string;
        subtitle: string;
      }[];
      simpleThumbnail: {
        progress: string;
        title: string;
        result: string;
      };
    };
  };
};

export const query = graphql`
  {
    landingContentJson {
      reward {
        description
        features
        step
        title {
          emphasis
          id
          text
        }
        iconThumbnails {
          icon {
            publicURL
          }
          id
          subtitle
          title
        }
        simpleThumbnail {
          progress
          title
          result
        }
        image {
          alt
          path {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;

const Reward = () => {
  const {
    landingContentJson: { reward },
  } = useStaticQuery<RewardData>(query);

  const {
    step,
    title,
    description,
    features,
    image,
    simpleThumbnail,
    iconThumbnails,
  } = reward;

  return (
    <FeatureSection py="6rem">
      <Flex
        className="featureRow"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        mx="-15px"
        px="15px"
      >
        <Box
          px="15px"
          width={[1, 1, 1 / 2]}
          flexBasis={['100%', '100%', '50%']}
          flexGrow={0}
          flexShrink={0}
          className="featureContent"
        >
          <FeatureTitle step={step} title={title} />
          <Text as="p" textStyle="displayLarge">
            {description}
          </Text>
          <FeatureList items={features} />
        </Box>
        <Box
          px="15px"
          width={[1, 1, 1 / 2]}
          flexBasis={['100%', '100%', '50%']}
          flexGrow={0}
          flexShrink={0}
        >
          <RewardImageSection>
            {iconThumbnails.map(
              ({ icon, id, title: thumbnailTitle, subtitle }) => (
                <IconThumbnail
                  key={id}
                  className="rewardThumbnail"
                  icon={icon.publicURL}
                  title={thumbnailTitle}
                  subtitle={subtitle}
                  alt={subtitle}
                />
              ),
            )}
            <SimpleThumbnail
              className="rewardThumbnail"
              title={simpleThumbnail.title}
              result={simpleThumbnail.result}
              progress={simpleThumbnail.progress}
            />
            <GatsbyImage
              image={getImage(image.path.childImageSharp)}
              alt={image.alt}
              className="rewardImage"
            />
          </RewardImageSection>
        </Box>
      </Flex>
    </FeatureSection>
  );
};

export default Reward;
