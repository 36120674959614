import React from 'react';
import styled from 'styled-components';

import { Container } from '@/components/Layout/Container';
import Text from '../Text/Text';

const SectionTitleWrapper = styled.div`
  .container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    margin-bottom: 1.2rem;
  }

  .underline {
    height: 4px;
    width: 9rem;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 8px;
  }
`;

const SectionTitle: React.FC<React.ComponentPropsWithRef<'div'>> = ({
  children,
  ...props
}) => (
  <SectionTitleWrapper {...props}>
    <Container className="container">
      <Text textStyle="secondaryHeading" as="h2" className="title">
        {children}
      </Text>
      <div className="underline" />
    </Container>
  </SectionTitleWrapper>
);

export default SectionTitle;
