import React from 'react';
import styled from 'styled-components';
import { BsStarFill } from 'react-icons/bs';

import Text from '../Text/Text';

interface ReviewCardProps {
  title: string;
  description: string;
  author: string;
}

const ReviewCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  border: 1px solid #f1f4f6;
  border-radius: 20px;
  user-select: none;
  transition: all 0.3s ease;

  & > *:not(:last-child) {
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 2.5rem;
  }

  .reviewMeta {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .author {
      color: ${(props) => props.theme.colors.secondaryText};
    }

    .rating {
      display: flex;
      align-items: center;

      & > .star:not(:last-child) {
        margin-right: 5px;
      }

      .star--full {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const ReviewCard: React.FC<ReviewCardProps> = ({
  title,
  description,
  author,
}) => (
  <ReviewCardWrapper>
    <Text fontSize="2rem" fontWeight={700} as="h3">
      {title}
    </Text>
    <Text textStyle="displayMedium" as="p">
      {description}
    </Text>
    <div className="reviewMeta">
      <Text textStyle="displaySmall" as="h4" className="author">
        {author}
      </Text>
      <div className="rating">
        {Array.from({ length: 5 }).map((_, index) => (
          <BsStarFill
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`star ${index + 1 > 5 ? '' : 'star--full'}`}
          />
        ))}
      </div>
    </div>
  </ReviewCardWrapper>
);
