import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import Text from '@/components/Text/Text';
import { EmphasedText } from '@/types';
import {
  FeatureSection,
  FeatureTitle,
  FeatureList,
} from '@/components/Feature/Feature';

const BackOfficeImage = styled.div`
  margin: 0 auto;
  width: 90%;
  padding: 6rem 1.5rem;
  position: relative;

  .backOfficeImage {
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  }

  .backOfficeImage:nth-child(1) {
    width: 100%;
    z-index: 10;
  }

  .backOfficeImage:nth-child(2) {
    width: 60%;
    position: absolute;
    z-index: 20;
    right: 0;
    top: -10px;
  }
`;

type BackOfficeData = {
  landingContentJson: {
    backoffice: {
      step: number;
      title: EmphasedText[];
      description: string;
      features: string[];
      images: {
        alt: string;
        path: {
          childImageSharp: ImageDataLike;
        };
      }[];
    };
  };
};

export const query = graphql`
  {
    landingContentJson {
      backoffice {
        description
        features
        step
        title {
          emphasis
          id
          text
        }
        images {
          alt
          path {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;

const BackOffice = () => {
  const {
    landingContentJson: { backoffice },
  } = useStaticQuery<BackOfficeData>(query);
  const { step, title, description, features, images } = backoffice;

  return (
    <FeatureSection>
      <Flex
        className="featureRow"
        alignItems="center"
        justifyContent="center"
        px="15px"
        mx="-15px"
        flexWrap="wrap"
      >
        <Box
          width={[1, 1, 1 / 2]}
          flexBasis={['100%', '100%', '50%']}
          flexGrow={0}
          flexShrink={0}
          className="featureContent"
          px="15px"
        >
          <FeatureTitle step={step} title={title} />
          <Text as="p" textStyle="displayLarge">
            {description}
          </Text>
          <FeatureList items={features} />
        </Box>
        <Box
          width={[1, 1, 1 / 2]}
          flexBasis={['100%', '100%', '50%']}
          flexGrow={0}
          flexShrink={0}
          className="imageContainer"
          px="15px"
        >
          <BackOfficeImage>
            {images.map(({ alt, path }, index) => (
              <GatsbyImage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                image={getImage(path.childImageSharp)}
                alt={alt}
                className="backOfficeImage"
              />
            ))}
          </BackOfficeImage>
        </Box>
      </Flex>
    </FeatureSection>
  );
};

export default BackOffice;
